import React, { useState } from "react";
import "./App.css";
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { DiceTokenABI } from "./abis/DiceToken";
import { DiceStakingABI } from "./abis/DiceStaking";
import { toast } from "react-toastify";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

// 1. Get projectId
const projectId = "cdfe03e466c6b2163cec6708112f4273";

// 2. Set chains
const mainnet = {
  chainId: 43114,
  name: "Avalanche C Chain",
  currency: "AVAX",
  explorerUrl: "https://snowtrace.io/",
  rpcUrl:
    "https://open-platform.nodereal.io/336906838ccd4ada98bad06c85251dd6/avalanche-c/ext/bc/C/rpc",
};

// 3. Create modal
const metadata = {
  name: "Avax Dice",
  description: "The first Dice on Avalanche",
  url: "https://staking.avaxdice.com",
  //   icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
});

const errorToast = (msg) =>
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

const toastSuccess = (msg) =>
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [stakedAmount, setStakedAmount] = useState(0);
  const [ethersProvider, setEthersProvider] = useState();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [diceBalance, setDiceBalance] = useState(0);
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);
  const [congratulate, setCongratulate] = useState(false);
  const [poolBalance, setPoolBalance] = useState("0.0");

  const { open } = useWeb3Modal();

  const handleWalletConnect = async () => {
    await open();
    if (isConnected) {
      const ethersProvider = new ethers.providers.JsonRpcProvider(
        "https://open-platform.nodereal.io/336906838ccd4ada98bad06c85251dd6/avalanche-c/ext/bc/C/rpc"
      );
      setEthersProvider(ethersProvider);
      setWalletAddress(address);
      getRewardsPool();
      getDiceBalance();
      getStakedAmount();
    }
  };

  function getStakingContract() {
    if (!isConnected) throw Error("User disconnected");
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = ethersProvider.getSigner();
    const stakingContract = new ethers.Contract(
      "0x95146E237493B02bAeb8D43503A0A225dC46DdB8",
      DiceStakingABI,
      signer
    );
    return stakingContract;
  }

  const getDiceContract = () => {
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = ethersProvider.getSigner();
    const diceContract = new ethers.Contract(
      "0x462D35385D0Ff12F268f964a9e86C415a0EB24dC",
      DiceTokenABI,
      signer
    );
    return diceContract;
  };

  async function getRewardsPool() {
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const balance = await ethersProvider.getBalance(
      "0xde1e6fcdd464122cfe05ea5709452fe797e3c926"
    );
    setPoolBalance(ethers.utils.formatEther(balance));
  }

  async function getStakedAmount() {
    const stakingContract = getStakingContract();
    const stakedAmount = await stakingContract.getStakedAmount(address);
    console.log(stakedAmount);
    setStakedAmount(ethers.utils.formatUnits(stakedAmount.toString()), 18);
  }

  async function getDiceBalance() {
    if (!isConnected) throw Error("User disconnected");
    const diceContract = getDiceContract();
    const balance = await diceContract?.balanceOf(address);
    setDiceBalance(ethers.utils.formatUnits(balance, 18));
  }

  async function unstakeDice() {
    try {
      setLoading(true);
      const stakingContract = getStakingContract();
      const tx = await stakingContract.unstake();
      await tx.wait();
      console.log(tx);

      toastSuccess(`Dice Unstaked https://snowtrace.io/tx/${tx.hash}`);
      setLoading(false);
      setStakedAmount(0);
    } catch (error) {
      errorToast(error.message);
      setLoading(false);
    }
  }

  const allowDiceSpending = async (amount) => {
    const diceContract = getDiceContract();
    const tx = await diceContract.approve(
      "0x95146E237493B02bAeb8D43503A0A225dC46DdB8",
      amount
    );
    return tx;
  };

  const handleDiceStake = async () => {
    try {
      setLoading(true);
      const stakingContract = getStakingContract();
      const amountInWei = ethers.utils.parseEther(amount);
      console.log(amountInWei.toString(), stakingContract);
      console.log("staking");
      const approveTx = await allowDiceSpending(amountInWei.toString());
      await approveTx.wait();
      toastSuccess(
        `Dice Allowance Given! https://snowtrace.io/tx/${approveTx.hash}`
      );
      const tx = await stakingContract.stake(amountInWei.toString());
      await tx.wait();
      console.log(tx);
      setLoading(false);
      toastSuccess(
        `Dice Staked Successfully! https://snowtrace.io/tx/${tx.hash}`
      );
      setCongratulate(true);
    } catch (error) {
      errorToast(error.message);
      setLoading(false);
    }
  };

  const handleAmountChange = (event) => {
    // Allow only numeric input with decimals
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const { width, height } = useWindowSize();

  return (
    <>
      <div className="App">
        <a href="https://avaxdice.com/" target="_blank">
          <img src="\logo.png" alt="logo" className="logo" />
        </a>
        <h2 className="heading">DICE Staking & Rewards</h2>
        {congratulate ? (
          <>
            <Confetti width={width} height={height} />
            <br />
            <h2 className="heading">
              $DICE Staked Successfully 🔥 U will now be receiving AVAX rewards
              every 2 days❤ ️
            </h2>
          </>
        ) : (
          <>
            {/* Check if walletAddress has a value */}
            {walletAddress ? (
              <div className="container">
                <div className="box stake-container">
                  <h2 className="heading2">Stake $DICE</h2>
                  <p className="paragraph">
                    Available: <span>{isConnected ? diceBalance : "0"}</span>{" "}
                    DICE
                  </p>
                  <input
                    type="number"
                    step="any" // Allows decimals
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="Enter amount"
                    className="input-field"
                  />

                  {loading ? (
                    <h2 className="heading">Loading...</h2>
                  ) : (
                    <button onClick={handleDiceStake} className="btn stake-btn">
                      Stake
                    </button>
                  )}
                </div>
                <div className="box claim-container">
                  <h2 className="heading2">Rewards Pool</h2>
                  <p className="paragraph">
                    Total: <span>{(poolBalance * 0.4).toFixed(3)}</span> AVAX
                  </p>
                  <p className="paragraph">
                    The rewards will be automatically sent to your wallet every
                    2 days. We bear the gas ❤️
                    <a href="https://t.me/AvaxDiceAnn">Announcements Channel</a>
                  </p>

                  {/* <button className="btn claim-btn">Claim</button> */}
                </div>
                <div className="box unstake-container">
                  <h2 className="heading2">Unstake $DICE</h2>
                  <p className="paragraph">
                    Staked: <span>{stakedAmount}</span> DICE
                  </p>
                  {loading ? (
                    <h2 className="heading">Loading...</h2>
                  ) : (
                    <button onClick={unstakeDice} className="btn unstake-btn">
                      Unstake
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <button className="btn connect-btn" onClick={handleWalletConnect}>
                Connect Wallet
              </button>
            )}
          </>
        )}

        <div className="socials">
          <a href="https://t.me/AvaxDice/" target="_blank">
            <img src="\TG.svg" alt="Telegram" className="telegram" />
          </a>
          <a href="https://x.com/Avax_Dice" target="_blank">
            <img src="\X.svg" alt="X" className="twitter" />
          </a>
        </div>
      </div>
    </>
  );
}

export default App;
